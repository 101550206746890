// Customizable Area Start
import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VolumeDown from "@material-ui/icons/VolumeDown";
import { VideosPlayer } from "components/src/CustomChart/VideosPlayer.web";
import {
  RepostIconfill,
  AddToPlaylistfill,
  DealIconfill,
  ShareIconfill,
  nxzloader,
  defaultProfile,
} from "blocks/dashboard/src/assets";

import PlaylistVideoPlayerController from "./PlaylistVideoPlayerController";
import { Row } from "antd";
import RepostPopup from "components/src/CustomChart/PopUps/RepostPopup.web";
import AddToQueuePopup from "blocks/dashboard/src/CustomComponent/AddToQueue/AddToQueuePopup.web";
import SharePlaylistPopup from "blocks/dashboard/src/CustomComponent/SharePlaylistPopup/SharePlaylistPopup.web";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "13px",
    fontFamily: "Inter",
  },
}));

export const MuiTooltip = (props: any) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default class PlaylistVideoPlayer extends PlaylistVideoPlayerController {
  constructor(props: any) {
    super(props);
  }

  handlerVolumeButton = () => {
    return <VolumeDown style={{ color: "white" }} />;
  };

  render() {
    const { data } = this.props;

    return (
      <>
        {Object.keys(data).length <= 0 ? (
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              width:"100%"
            }}
          >
            <img src={nxzloader} style={{ width: "5%" }} />
          </Row>
        ) : (
          <>
            <div
              // className="videoPlayerWrapper_in_playlist videoplayer_background_playlist"
              className="videoPlayerWrapper_in_playlist videoplayer_background"
            >
              <div
                onMouseMove={this.handleMouseMoveVal}
                onMouseLeave={this.hanldeMouseLeaveval}
                style={{ height: "100%" }}
              >
                <VideosPlayer
                  topvideo={data && this.state.topVideoObj.video_file}
                  trackId={this.state.trackId}
                  setVideoCount={(id: any) => this.setVideoCount(id)}
                  playVideoFromRedirection={this.state.playVideoFromRedirection}
                  setPlayVideoFromRedirection={(data: boolean) => {
                    this.setState({ playVideoFromRedirection: data });
                  }}
                  currentTrack={this.props.currentTrack.attributes.playlist_items.data}
                  setTopVideoObj={(data: any) =>
                    {
                    this.setState({ topVideoObj: data })
                    this.findNextObject(data)
                  }
                  }
                  setSetCurrentKey={(data: any) =>
                    this.setState({ currentKey: data })
                  }
                  setTrackId={(data: any) => this.setState({ trackId: data })}
                  setUserTrack={(tracks:any)=>this.setState({userTrack:tracks})}
                  setRepostCount={(data: any) =>
                    this.setState({ repostCount: data })
                  }
                  getFilterTrack={(id: any) => this.getFilterTrack(id)}
                  artWork={this.state.topVideoObj?.art_work}
                  playingVideo = {this.state.topVideoObj} 
                />
                <div
                  ref={this.videosPlayRef}
                  className="videos_palylist_features_icons"
                  // style={boxStyle}
                >
                  <div>
                    <MuiTooltip title="Repost" placement="left">
                      <img
                        src={RepostIconfill}
                        alt=""
                        width="32px"
                        height="32px"
                        style={{ filter: "greyscale(1)" }}
                        className="videos_icon_margin"
                        onClick={() => this.setState({ openRepostPopup: true })}
                      data-test-id="img-repost"
                      />
                    </MuiTooltip>
                  </div>
                  <div>
                    <MuiTooltip title="Share" placement="left">
                      <img
                        src={ShareIconfill}
                        alt=""
                        width="32px"
                        height="32px"
                        className="videos_icon_margin"
                        onClick={this.onShare}
                      data-test-id="img-share"

                      />
                    </MuiTooltip>
                  </div>
                  <div>
                    <MuiTooltip title="Playlist" placement="left">
                      <img
                        src={AddToPlaylistfill}
                        alt=""
                        width="32px"
                        height="32px"
                        className="videos_icon_margin"
                        onClick={() => this.setState({ openModal: true })}
                        data-test-id="img-playlist"
                     
                     />
                    </MuiTooltip>
                  </div>
                  <div className="cursor-not-allowed">
                    <MuiTooltip title="Deal" placement="left">
                      <img
                        src={DealIconfill}
                        alt=""
                        width="32px"
                        height="32px"
                        className="videos_icon_margin"
                      />
                    </MuiTooltip>
                  </div>
                </div>
              </div>
            </div>
            <RepostPopup
              open={this.state.openRepostPopup}
              onClose={this.handleRepostClose}
              profileImage={
                this.state.topVideoObj?.art_work ||
                this.state.topVideoObj?.cover_image ||
                defaultProfile
              }
              artistName={this.state.topVideoObj?.artist_name}
              songName={this.state.topVideoObj?.title}
              repost_count={this.repostSubmit}
              handleChartTrackRepost={this.handleChartTrackRepost}
              _handleTextFieldChange={this.handleCaptionChange}
            />

            <AddToQueuePopup
              id="Queue"
              open={this.state.openModal}
              handleClose={() => {
                this.setState({ openModal: false });
              }}
              playlistType="video"
              trackData={this.props.data}
            />

            <SharePlaylistPopup
              id="Video"
              open={this.state.OpenScanPopup}
              handleClose={() => this.setState({ OpenScanPopup: false })}
              track={this.props.data}
            />
          </>
        )}
      </>
    );
  }
}

// Customizable Area End
