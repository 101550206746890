// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../../components/src/common";
export const configJSON = require("./config.js");
import { AudioContextApi } from "../../AudioContext/AudioContext.web";

export interface Props {
  media: any;
  mediaType: string;
  playMedia: (media: any, type: string) => void;
  viewProfile: () => void;
  selectedTrackMediaDetails: any;
  selectedTrackStatsDetails: any;
  selectedTab?: any;
  handleAddNewUser: (newUser: any) => void;
  setTrackIndex: any;
  isShowMsgModal: any;
  closeNewMessage: any;
  openNewMessage: any;
}

interface S {
  isCopiedModalOpen: boolean;
  sendLinkViaDM: boolean;
  sendLinkCopy: any;
}
interface SS {
  id: any;
}

export default class AudioVideoInfoCardCPController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiHandleCopyCountCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isCopiedModalOpen: false,
      sendLinkViaDM: false,
      sendLinkCopy: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  handlePlay = () => {
    this.props.playMedia({}, this.props.mediaType);
  };

  handlePlayAudioVideo = () => {
    const { handlePlayList } = this.context;

    if (
      this.props.selectedTrackMediaDetails?.attributes?.track_upload_type !=
      ("Video" || "video") && typeof(handlePlayList) === 'function'
    ) {
      handlePlayList(
        this.props.selectedTrackMediaDetails,
        0,
        [],
        this.props.selectedTrackMediaDetails?.attributes?.track_upload_type
      );
    } else {
      HISTORY.push(
        `/home/Charts/videos?id=${this.props.selectedTrackMediaDetails?.id}`
      );
    }
  };

  playOrPauseSong = () => {
    const { setHandleAudioVideo, handleAudioVideo } = this.context;

    if (
      this.props.selectedTrackMediaDetails?.attributes?.track_upload_type !=
      ("Video" || "video") && typeof(setHandleAudioVideo) === 'function'
    ) {
      setHandleAudioVideo(handleAudioVideo == 1 ? 2 : 1);
    }
  };
  closeMsgModalComment = (newUser: any) => {
    this.props.closeNewMessage();
    this.props.handleAddNewUser(newUser);
    this.props.setTrackIndex(newUser.id);
  };

  increaseCopylinkCount = () => {
    const header = {
      "Content-Type": "application/json;charset=UTF-8" ,
      'token' : localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHandleCopyCountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/views/update_copy_link_count?id=${this.props.selectedTrackMediaDetails?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  linkCopy = () => {
     const el = document.createElement("input");
     el.value = window.location.origin.concat(
       `/home/expand/song/songId=${this.props.selectedTrackMediaDetails?.id}`
     );
     document.body.appendChild(el);
     el.select();
     el.focus();
     navigator?.clipboard?.writeText(
       window.location.origin.concat(
         `/home/expand/song/songId=${this.props.selectedTrackMediaDetails?.id}`
       )
      );
     document.body.removeChild(el);
     this.setState({ isCopiedModalOpen: true });
     this.increaseCopylinkCount();
   };

  closeMsgModal = () => {
    this.setState({ sendLinkViaDM: false });
  };

  handleSendBtn=()=>{
    this.setState({
      sendLinkCopy: window.location.origin.concat(
        `/home/expand/song/songId=${this.props.selectedTrackMediaDetails?.id}`
      ),
      sendLinkViaDM: true,
    });
  }


  handleCopiedLinkFalse=()=>{
    this.setState({ isCopiedModalOpen: false });

  }

  handleSendLinkFalse=()=>{
    this.setState({ sendLinkViaDM: false });

  }
  handleCopyCountCallId = (responseJson: any) => {
    
  };
  handleRestApiCall = (apiRequestCallId: any, responseJson: any) => {
    if(apiRequestCallId==this.apiHandleCopyCountCallId){
      this.handleCopyCountCallId(responseJson);
    }
  };

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId == null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.handleRestApiCall(apiRequestCallId, responseJson);
    }
  }
}
AudioVideoInfoCardCPController.contextType = AudioContextApi;
// Customizable Area End
