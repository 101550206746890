Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AudioMusic";
exports.labelBodyText = "AudioMusic Body";

exports.btnExampleTitle = "CLICK ME";
exports.btnExampleTitle1 = "CLICK ME 1";
exports.btnExampleTitle2 = "CLICK ME2";
exports.btnExampleTitle3 = "CLICK ME3";
exports.btnExampleTitle4 = "CLICK ME4";
exports.btnExampleTitle5 = "CLICK ME5";
exports.btnExampleTitle6 = "CLICK ME6";
exports.btnExampleTitle7 = "CLICK ME7";
exports.btnExampleTitle8 = "CLICK ME8";
exports.btnExampleTitle9 = "CLICK ME9";
exports.btnExampleTitle0 = "CLICK ME00";
// Customizable Area End