// Customizable Area Start
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";

export const configJSON = require("../../config.js");

// Customizable Area Start

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setSelectedComment: (track: any) => void;
  setSelectedMedia: (track: any) => void;
  setUnreadComments: (comments: any) => void;
  setSelectedTrack: (track: any) => void;
  handleAddNewUser: (track: any) => void;
  setTrackIndex:(id: any) => void;
  selectedUserType:(name: any) => void;
  closeNewMessage:any;
  openNewMessage:any;
  isShowMsgModal: any;
  selectedComment:any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showLoader: boolean;
  tracks: any;
  showCommentChatDialog: boolean;
  selectedMedia: any;
  selectedMediaReplies: any;
  showMediaDetailDialog: boolean;
  selectedTab: string,
  apiToken: any,
  selectedComment: any,
  loading: any,
  searchTerm: any,
  selectedCommentForReply: any,
  newComment: any;
  selectedTrack: any;
  selectedTrackMediaDetails: any;
  selectedTrackStatsDetails: any;
  openMessageLink: any;
  selectedTrackIndex: any;
  anchorEl: any;
  isShowMsgModal: boolean;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CommentListCPController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apigetCommentedTracksCallId: string = "";
  apigetSelectedTrackComments: string = "";
  apigetUnreadRepliesCallId: string = "";
  apigetSearchTermCommentedTracksCallId: string = "";
  apipostReplyOnCommentCallId: string = "";
  apipostSendCommentTrackCallId: string = "";
  apigetTrackBioDetailsCallId: string = "";
  apigetTrackStatsDetailsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      showLoader: false,
      tracks: [],
      showCommentChatDialog: false,
      apiToken: localStorage.getItem('token'),
      selectedMedia: '',
      selectedMediaReplies: [],
      showMediaDetailDialog: false,
      selectedTab: 'Track',
      selectedComment: [],
      loading: false,
      searchTerm: '',
      selectedCommentForReply: '',
      newComment: '',
      selectedTrack: '',
      selectedTrackMediaDetails: '',
      selectedTrackStatsDetails: '',
      openMessageLink: false,
      selectedTrackIndex: null,
      anchorEl: null,
      isShowMsgModal: false,

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCommentedTracks(this.state.selectedTab);
    if (this.state.selectedMedia == '') {
      this.getUnreadReplies()
    }
    // Customizable Area End
  }


  // Customizable Area Start
  viewTrackDetail = (track: any) => {
    this.props.setSelectedMedia(track)
    this.props.setSelectedTrack(track)
    this.getTrackMediaDetails(track)
    this.setState({ selectedTrack: track })
    this.getSelectedTrackComments(track)
    this.getTrackStatsDetails(track)
    if (window.innerWidth <= 1239) {
      this.setState({
        selectedMedia: track,
        showMediaDetailDialog: true,
      })
    }
  }
  viewTrackComments = (track: any) => {
    this.getSelectedTrackComments(track)
    this.props.setSelectedMedia(track)
    this.setState({ selectedTrack: track })
    this.props.setSelectedTrack(track)
    this.setState({
    }, () => {
      this.fetchCommentReplies()
    })
  }

  handleMenu = (event: any) => {

    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  openNewMessage = () => {
    this.setState({ isShowMsgModal: true })
  }
  closeNewMessage = () => {
    this.setState({ isShowMsgModal: false ,openMessageLink:false})

  }

  closeMsgModal = (newUser: any) => {
    this.setState({ isShowMsgModal: false,openMessageLink:false })
    this.props.selectedUserType("dm")
    this.closeNewMessage()
   this.props.handleAddNewUser(newUser)
    this.props.setTrackIndex(newUser.id)

  }
  closeCommentChatDialog = () => {
    this.setState({
      selectedMedia: '',
      showCommentChatDialog: false,
    })
  }
  sendComment = (mediaId: any) => { }
  
  handlePageChange = (page: string) => {
    this.setState({
      selectedTab: page,
      tracks: [],
      selectedTrackIndex: null,
      selectedCommentForReply: ''
    })
    this.getCommentedTracks(page);
    this.props.setSelectedTrack('')
  }
  closeProfileMedia = () => {
    this.setState({
      selectedMedia: '',
      showMediaDetailDialog: false,
    })
  }

  closeCommentReply = () => {
    this.setState({ selectedCommentForReply: '' })
  }

  selectedComentReply = (comment: any) => {
    this.setState({ selectedCommentForReply: comment })
  }

  setOnChangeNewComment = (value: any) => {
    if (value != '') {
      this.setState({ newComment: value });
    }
  }

   getReadStatus = (item: any) => {
    try { 
        return !(item?.attributes?.comments[0]?.attributes?.is_read)
    } catch (error) {
        return false
    }
}

  sendNewComment = (value: any) => {
    if (this.state.selectedCommentForReply == '') {
      this.postNewCommentOnTracks()
      const element = document.getElementById('scroll-new-comment-dialog')
        if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    } else {
      const element = document.getElementById(`comment_id_${this.state.selectedCommentForReply.id}`)
        if (element) {
        element.scrollIntoView({ behavior: 'smooth' , block: 'nearest'});
        }
      this.postReplyOnComment()
    }
  }

  postNewCommentOnTracks = () => {
    
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const data = {
      description: this.state.newComment,
      add_track_id: this.state.selectedTrack.id
    };

     const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
     );

    
     this.apipostSendCommentTrackCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/comments?description=${this.state.newComment}&add_track_id=${this.state.selectedTrack.id}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  postReplyOnComment = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };

    const data = {

      description: this.state.newComment,
      comment_id: this.state.selectedCommentForReply.id

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apipostReplyOnCommentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/replies`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  getTrackMediaDetails = (track: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetTrackBioDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/expan_track?id=${track.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTrackStatsDetails = (track: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetTrackStatsDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_messages/messages/inbox_state?id=${track.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  debounce = (callback: any, delay: any) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

  debounceLog = this.debounce((text: any) => this.getSearchkeyCommentedTracks(text), 1000);

  searchComment = (value: any) => {
    this.setState({ searchTerm: value.target.value })
    this.debounceLog(value.target.value)
  }




  getSearchkeyCommentedTracks = (searchTerm: any) => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetSearchTermCommentedTracksCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/commented_tracks_web?upload_type=${this.state.selectedTab}&search=${searchTerm}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  getSelectedTrackComments = (track: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetSelectedTrackComments = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/show_comment?add_track_id=${track.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getUnreadReplies = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    )
    this.apigetUnreadRepliesCallId = requestMessage.messageId;

  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/unread_reply_comments`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

   requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    )

    runEngine.sendMessage(requestMessage.id, requestMessage)
  
  }

  getCommentedTracks = (selctedType: any,isLoading:boolean=true) => {
    this.setState({ loading: isLoading })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      'token': this.state.apiToken
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetCommentedTracksCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_activityfeed/commented_tracks_web?upload_type=${selctedType}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getCommentedTracksApiSuccessCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ tracks: responseJson?.data })
    }
  }
  
  componentDidUpdate(prevProps:any) {
    if (prevProps.selectedComment !== this.props.selectedComment) {
      this.getCommentedTracks(this.state.selectedTab,false);
    }
  }

  getselectedTrackcommentsApiSuccessCall = (responseJson: any) => {
    this.props.setSelectedComment(responseJson.data)
    this.setState({ selectedComment: responseJson.data });
  }

  getUnreadRepliesApiSuccessCall = (responseJson: any) => {
    this.props.setUnreadComments(responseJson?.unread_comments || 0)
  }

  getSearchTermCommentedTracksApiSuccessCall = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ tracks: responseJson?.data })
    } else if (responseJson?.message) {
      this.setState({ tracks: [] })
    } else {
      this.setState({ tracks: [] })
    }
  }

  // Customizable Area End

  // Customizable Area Start

  postNewCommentOnTracksApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedComment: [responseJson?.data, ...this.state.selectedComment], newComment: '' })
  }

  addReplyToComment = (commentsList: any, reply: any) => {
    let comments = Object.assign(commentsList, [])
    for (let comment of comments) {
      if (comment.attributes.id === reply.attributes.comment_id) {
        comment.attributes.replies = [reply, ...comment.attributes.replies]
        break;
      }
    }
    return comments;
  }

  postReplyOnCommetApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedComment: this.addReplyToComment(this.state.selectedComment, responseJson?.data), selectedCommentForReply: '', newComment: '' })
  }

  getTrackBioDetailsApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedTrackMediaDetails: responseJson?.data })
  }

  getTrackStatsDetailsApiSuccessCall = (responseJson: any) => {
    this.setState({ selectedTrackStatsDetails: responseJson })
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.apigetCommentedTracksCallId) {
        this.getCommentedTracksApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false })
      }
      else if (apiRequestCallId === this.apigetSelectedTrackComments) {
        this.getselectedTrackcommentsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetUnreadRepliesCallId) {
        this.getUnreadRepliesApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetSearchTermCommentedTracksCallId) {
        this.getSearchTermCommentedTracksApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ loading: false })
      }
      else if (apiRequestCallId === this.apipostSendCommentTrackCallId) {
        this.postNewCommentOnTracksApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apipostReplyOnCommentCallId) {
        this.postReplyOnCommetApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTrackBioDetailsCallId) {
        this.getTrackBioDetailsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else if (apiRequestCallId === this.apigetTrackStatsDetailsCallId) {
        this.getTrackStatsDetailsApiSuccessCall(responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
      }

    }
    // Customizable Area End
  }

  fetchCommentReplies = () => {
    if (window.innerWidth <= 1239) {
      this.setState({
        showCommentChatDialog: true
      })
    }
  }
}
// Customizable Area End