// Customizable Area StartS
import React, { useContext } from 'react'
import { Typography } from 'antd';
import { commentBlue, DummyProfileImage, fullscreen, pauseA, playButton, plays, reshared, streaming } from '../../../../dashboard/src/assets';
import millify from 'millify';
import { useHistory } from 'react-router-dom';
import { AudioContextApi } from '../../../../dashboard/src/AudioContext/AudioContext.web';
//@ts-ignore
import Waveform from '../../../../../components/src/CustomChart/Songs/Waveform';
import UnlockContentCommon from 'blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web';
import { unlockRedirectionCheck } from 'blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web';

const playWaveSubFunc = (play: any, selectedPlaylistId: any, selectedSong: any,song: any,handleAudioVideo: any, setHandleAudioVideo: any) =>{
    return (
      <div style={{position: 'relative' ,

height: play?.id == selectedPlaylistId && selectedSong && selectedSong?.split('"')[1] === song?.id && handleAudioVideo === 2 ? "10px" : "15px",
width: play?.id == selectedPlaylistId && selectedSong && selectedSong?.split('"')[1] === song?.id && handleAudioVideo === 2 ? "10px" : "15px",
marginRight: "4px",
      }}>
        <img src={play?.id == selectedPlaylistId && selectedSong && selectedSong?.split('"')[1] === song?.id && handleAudioVideo === 2 ? pauseA : playButton} alt=""
          style={{ marginRight: "4px", cursor: 'pointer', position: 'absolute' }}
          height={play?.id == selectedPlaylistId && selectedSong && selectedSong?.split('"')[1] === song?.id && handleAudioVideo === 2 ? "10px" : "15px"}
          width={play?.id == selectedPlaylistId && selectedSong && selectedSong?.split('"')[1] === song?.id && handleAudioVideo === 2 ? "10px" : "15px"}
          onClick={() => { unlockRedirectionCheck(setHandleAudioVideo,[handleAudioVideo == 1 ? 2 : 1], song) }} />
          <div style={{
            position: 'absolute',
            height: play?.id == selectedPlaylistId && selectedSong && selectedSong?.split('"')[1] === song?.id && handleAudioVideo === 2 ? "10px" : "15px",
            width: "10px",
            marginRight: "4px",
          }}>
            <UnlockContentCommon trackData={song} height="100%" width="100%" padding="2px" fontSize="9px"/>
          </div>
      </div>
    )
}

const PlaylistTracks = ({ song, play, selectedPlaylistId, playlistSongSelected, index, selectedSong }: any) => {
  //@ts-ignore
  const {handleAudioVideo,setHandleAudioVideo} = useContext(AudioContextApi)
  const history = useHistory();
  return (

    <div className="musicRow hide-playicons-in-mobile-view" style={{ display: 'flex', width: '100%', padding: '5px',alignItems:"center" }} >
      <div className="div1-width" style={{}}>
        <Typography style={{ color: 'white', marginRight: '10px', fontSize: 'small' }}>{index < 9 ? '0' : ''}{index + 1}</Typography>
      </div>
      <div className="div2-width" style={{}}>
        <img src={song?.attributes.art_work || DummyProfileImage} style={{ height: '30px', width: '35px', borderRadius: '5px' }} alt="" />
      </div>
      <div className="div3-width" style={{}}>
        <div style={{ display: "flex", flexDirection: "column", color: 'white', margin: '0 3px' }}>
          <Typography style={{ fontWeight: 'bold', fontSize: '10px', color: 'white' }}>{song?.attributes.title}</Typography>
          <Typography style={{ color: "rgb(143, 144, 146)", fontSize: '10px' }}>{song?.attributes.artist_name}</Typography>
        </div>
      </div>
      <div className="div4-width" test-id="playlistsongselectedtest_id" style={{display: "flex",
    alignItems: "center"}} onClick={() => unlockRedirectionCheck(playlistSongSelected,[play, song],song)}>
       {playWaveSubFunc(play, selectedPlaylistId, selectedSong,song,handleAudioVideo, setHandleAudioVideo)}
        {/* <img className="hide_playlist_wave" src={streaming} style={{ height: '20px', width: '80%' }} /> */}
       {song.attributes.track_file?
       <Waveform
         url={song.attributes.track_file}
         id={song?.id}
         height={40}
       />
       :
       <img className="hide_playlist_wave" src={streaming} style={{ height: '20px', width: '80%' }} /> 
      
      }
      </div>
      <div className="div5-width" style={{}}>
        <div className="hide-playicons-in-mobile-view" style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", height: '100%' }}>

          <div style={{ display: 'flex' }}>
            <img src={plays} style={{ height: "18px", width: "20px", marginTop: "4px" }} />
            <p className="ml_02" style={{
              textAlign: "start",
              color: "#fff", fontSize: "13px", marginTop: "4px"
            }}>
              {song?.attributes?.play_count ? millify(song?.attributes?.play_count) : "0"}
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <img src={commentBlue} style={{ height: "16px", width: "15px", marginLeft: '4px', marginTop: "5px" }} />
            <p className="ml_05" style={{
              textAlign: "start",
              color: "#fff", fontSize: "13px", marginTop: "4px"
            }}>
              {song?.attributes?.comments_count ? millify(song?.attributes?.comments_count) : "0"}
            </p>
          </div>
          <div style={{ display: 'flex' }}>
            <img src={reshared} style={{ height: "22px", width: "23px", marginTop: "0px" }} />
            <p className="ml_02" style={{
              color: "#fff", fontSize: "13px"
            }}>
              {song?.attributes?.repost_count ? millify(song?.attributes?.repost_count) : "0"}

            </p>
          </div>



        </div>
      </div>

      <div className="div6-width">
        <img src={fullscreen} style={{ height: "18px", width: "18px", marginTop: "-2px", marginLeft: '5px', cursor: 'pointer' }} onClick={() => unlockRedirectionCheck(() => {
          history.push(`/home/expand/song/songId=${song.id}`)
        },"",song)} />
      </div>
    </div>

  )
}

export default PlaylistTracks
// Customizable Area End